import styled, { css } from 'styled-components';
import {
  TGridItemStyledProps,
  TGridStyledProps,
  TGridItemGaps,
} from './Grid.type';

import { forEachBreakpoint } from '@dist-property-frontend/configs';

const getValuePx = (value: string) => Number(value.replace('rem', ''));

const getGridItemWidth = (props: TGridItemStyledProps) => {
  let styles = '';

  forEachBreakpoint((breakpoint) => {
    const start = props.startColumn ? props.startColumn[breakpoint] : 'auto';
    const span = props.spanColumns ? props.spanColumns[breakpoint] : 'auto';
    const textAlign = props.itemTextAlign?.[breakpoint];

    const gridColumn = () => {
      if (start === 'auto') return `auto / span ${span}`;
      if (span === 'auto') return `${start} / auto`;
      return `${start} / span ${span}`;
    };

    const gridColumnValue = start && span ? gridColumn() : '';

    styles += `
      @media only screen and (min-width: ${props.theme.toolkit.breakpoints[breakpoint]}px) {
        ${gridColumnValue ? `grid-column: ${gridColumnValue};` : ''}
        ${textAlign ? `text-align: ${textAlign};` : ''}
      }
    `;
  });

  return css`
    ${styles}
  `;
};

const getGridItemRows = (props: TGridItemStyledProps) => {
  let styles = '';

  forEachBreakpoint((breakpoint) => {
    const start = props.rowStart ? props.rowStart[breakpoint] : undefined;
    const span = props.rowSpan ? props.rowSpan[breakpoint] : undefined;

    styles += `
      @media only screen and (min-width: ${props.theme.toolkit.breakpoints[breakpoint]}px) {
        ${start && span ? `grid-row: ${start} / span ${span};` : ''}
      }
    `;
  });

  return css`
    ${styles}
  `;
};

const isZeroGap = (gaps: TGridItemGaps) => {
  return Object.values(gaps).every((gap) => gap === 'S0');
};

const getGapStyles = (props: TGridStyledProps) => {
  let styles = '';
  let gapType = 'column-gap';
  let gaps = props.columnGap;

  if (props.gridGap && !isZeroGap(props.gridGap)) {
    gapType = 'grid-gap';
    gaps = props.gridGap;
  }

  forEachBreakpoint((breakpoint) => {
    const gap = gaps?.[breakpoint];
    if (gap && typeof gap === 'string') {
      styles += `
        @media only screen and (min-width: ${props.theme.toolkit.breakpoints[breakpoint]}px) {
          ${gapType}: ${getValuePx(props.theme.toolkit.spacing[gap])}rem;
        }
      `;
    }
  });

  return css`
    ${styles}
  `;
};

const getMaxWrappedLines = (props: TGridItemStyledProps) => {
  let styles = '';

  forEachBreakpoint((breakpoint) => {
    const lines = props.maxWrappedLines?.[breakpoint];

    if (lines) {
      styles += `
        @media only screen and (min-width: ${props.theme.toolkit.breakpoints[breakpoint]}px) {
          @supports ((display: -webkit-box) and (-webkit-line-clamp: ${lines})) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: ${lines};
            overflow: hidden;
            white-space: normal;
            p {
              display: inline;
            }
          }
        }
      `;
    }
  });

  return css`
    ${styles}
  `;
};

const getHideElementStyles = (
  props: TGridItemStyledProps | TGridStyledProps,
) => {
  let styles = '';
  forEachBreakpoint((breakpoint) => {
    const hide = props.hideElement?.[breakpoint];

    if (hide) {
      styles += `
        @media only screen and (max-width: ${props.theme.toolkit.breakpoints[breakpoint]}px) {
          display: none;
        }
      `;
    }
  });
  return css`
    ${styles}
  `;
};

const getExtraPaddingTop = (props: TGridItemStyledProps) => {
  let styles = '';
  forEachBreakpoint((breakpoint) => {
    const paddingValue = props.extraPaddingTop?.[breakpoint];
    if (paddingValue !== undefined) {
      styles += `
        @media only screen and (min-width: ${props.theme.toolkit.breakpoints[breakpoint]}px) {
          padding-top: ${paddingValue}px; 
        }
      `;
    }
  });

  return css`
    ${styles}
  `;
};

const GridItem = styled.div<TGridItemStyledProps>`
  margin: 0;
  box-sizing: border-box;
  ${(props) => getGridItemWidth(props)}
  ${(props) => getMaxWrappedLines(props)}
  ${getGridItemRows}
  ${getHideElementStyles}
  ${getExtraPaddingTop}
  align-self: ${(props) =>
    props.verticalSelfAlignment ? `${props.verticalSelfAlignment}` : ''};
`;

const Grid = styled.div<TGridStyledProps>`
  min-width: 0;
  min-height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  ${getHideElementStyles}
  ${({ alignItems }) =>
    alignItems &&
    `
    align-items: ${alignItems};
  `}
  ${({ justifyContent }) =>
    justifyContent &&
    `
    justify-content: ${justifyContent};
  `} 

  ${getGapStyles}
`;

export { Grid, GridItem };
