import Link from 'next/link';
import { useState } from 'react';
import * as Styled from './NavButton.styled';
import { useRouter } from 'next/router';

export type NavItemData = {
  dataTracking?: string;
  linkText: string;
  url?: string;
  subItems?: { linkText: string; url: string }[];
};

type NavItemProps = {
  item: NavItemData;
  index: number;
};

export const NavButton = ({ item, index }: NavItemProps) => {
  const [dropdown, setDropdown] = useState(false);
  const routerHook = useRouter();
  // NOTE: This is a very basic check put in for the enquiries story requirement. The active state logic has not
  // been added for the head yet. This will need to be updated in the future
  const asPath = routerHook.asPath;
  const isNavActive = asPath === item.url || dropdown;
  const navClassName = isNavActive ? 'active' : '';

  const onMouseEnter = () => {
    window.innerWidth > 1024 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 1024 && setDropdown(false);
  };

  return (
    <Styled.NavItem
      key={index}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <button
        type="button"
        {...(item.subItems?.length !== undefined && {
          'aria-haspopup': true,
        })}
        className={navClassName}
        {...(item.subItems?.length !== undefined && {
          onClick: () => setDropdown((prev) => !prev),
        })}
        {...(item.dataTracking && {
          'data-tracking': item.dataTracking,
        })}
      >
        {!item.url ? (
          item.linkText
        ) : (
          <Link href={item.url}>{item.linkText}</Link>
        )}
      </button>
      {item.subItems && (
        <Styled.SubNavWrapper
          aria-hidden={!dropdown}
          aria-expanded={dropdown}
          aria-label={`${item.linkText} submenu`}
          id={item.linkText}
        >
          {item.subItems?.map((item, index) => (
            <Styled.SubNavItem key={index}>
              <Link href={item.url}>{item.linkText}</Link>
            </Styled.SubNavItem>
          ))}
        </Styled.SubNavWrapper>
      )}
    </Styled.NavItem>
  );
};
