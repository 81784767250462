import React, { forwardRef, ReactNode } from 'react';
import { useInput } from './Input.hook';

import * as Styled from './Input.styled';
import { TInput } from './Inputs.types';
import Icon from '../icon/Icon';
import { ButtonSizes, ButtonTypes } from '../button/Button.types';
import { Button } from '../button/Button';

export const Input = forwardRef<HTMLInputElement, TInput>(
  (props: TInput, ref) => {
    const {
      leading,
      trailing,
      hasError,
      disabled,
      onClear,
      onFocus,
      onBlur,
      ...rest
    } = props;
    const { isFocussed, handleOnFocus, handleOnBlur } = useInput(
      onFocus,
      onBlur,
    );

    const showClearButton = onClear && rest.value && !disabled;
    const isLeadingSlotButton =
      React.isValidElement(leading) && leading.type === Button;

    let trailingElementsToDisplay: ReactNode[] = [];
    trailingElementsToDisplay = showClearButton
      ? [
          <Button
            aria-label="Clear search"
            buttonSize={ButtonSizes.X_SMALL}
            buttonType={ButtonTypes.GHOST}
            Icon={<Icon icon="CLOSE" size={16} />}
            key="clearButton"
            onClick={onClear}
          />,
        ]
      : [];
    if (trailing) {
      if (Array.isArray(trailing)) {
        trailingElementsToDisplay.push(...trailing);
      } else {
        trailingElementsToDisplay.push(trailing);
      }
    }
    const isLastTrailingSlotButton =
      Array.isArray(trailingElementsToDisplay) &&
      trailingElementsToDisplay.length > 0 &&
      React.isValidElement(
        trailingElementsToDisplay[trailingElementsToDisplay.length - 1],
      ) &&
      (
        trailingElementsToDisplay[
          trailingElementsToDisplay.length - 1
        ] as React.ReactElement
      ).type === Button;

    return (
      <Styled.Container
        hasError={hasError}
        disabled={disabled}
        focussed={isFocussed}
        isLeadingSlotButton={isLeadingSlotButton}
        isLastTrailingSlotButton={isLastTrailingSlotButton}
      >
        {leading && <Styled.Leading>{leading}</Styled.Leading>}
        <Styled.Input
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
        {trailingElementsToDisplay.length > 0 && (
          <Styled.Trailing>{trailingElementsToDisplay}</Styled.Trailing>
        )}
      </Styled.Container>
    );
  },
);

export default Input;
