import styled, { css } from 'styled-components';
import { TInputStyled } from './Inputs.types';

const focussedContainer = css`
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
`;

const errorContainer = css`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.toolkit.brand.warning.DARK};
`;

const disabledContainer = css`
  background-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTEST};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  color: ${({ theme }) => theme.toolkit.brand.grey?.BASE};
  input::placeholder {
    color: ${({ theme }) => theme.toolkit.brand.grey?.BASE};
  }
`;

export const Container = styled.div<TInputStyled>`
  align-items: center;
  background: #ffffff;
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  display: flex;
  height: ${({ theme }) => theme.toolkit.spacing.L48};
  justify-content: stretch;
  padding-bottom: ${({ theme }) => theme.toolkit.spacing.S12};
  padding-top: ${({ theme }) => theme.toolkit.spacing.S12};
  padding-left: ${({ isLeadingSlotButton, theme }) =>
    isLeadingSlotButton ? theme.toolkit.spacing.S8 : theme.toolkit.spacing.M20};
  padding-right: ${({ isLastTrailingSlotButton, theme }) =>
    isLastTrailingSlotButton
      ? theme.toolkit.spacing.S8
      : theme.toolkit.spacing.M20};

  ${({ focussed }) => focussed && focussedContainer}
  ${({ hasError }) => hasError && errorContainer}
  ${({ disabled }) => disabled && disabledContainer}
`;

export const Input = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  padding: 0;
  font: inherit;
  background: none;
  border: none;
  color: inherit;
  outline: none;
  text-overflow: ellipsis;
  ${({ theme }) => theme.toolkit.typography.fontSizes.M16};

  ::placeholder {
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};
  }
`;

export const Leading = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.toolkit.spacing.S8};
  height: 100%;
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M} 0 0
    ${({ theme }) => theme.toolkit.borderRadius.M};
  ${({ theme }) => theme.toolkit.typography.fontSizes.M16};

  &:empty {
    margin-right: 16px;
  }
`;

export const Trailing = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.toolkit.spacing.S8}`};
  height: 100%;
  margin-left: ${({ theme }) => theme.toolkit.spacing.S8};
  border-radius: 0 ${({ theme }) => theme.toolkit.borderRadius.M}
    ${({ theme }) => theme.toolkit.borderRadius.M} 0;
  ${({ theme }) => theme.toolkit.typography.fontSizes.M16};

  &:empty {
    margin-left: 16px;
  }
`;
