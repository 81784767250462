import styled from "styled-components";

type NoImageWrapperProps = {
    backgroundColor?: string;
    backgroundImage?: string;
};

export const NoImageBannerWrapper = styled.div<NoImageWrapperProps>`
    background-color: ${({ backgroundColor, theme }) =>  backgroundColor || theme.color.GREY_DARKER};
    ${({ backgroundImage }) => backgroundImage ? `
        background-image: url(${backgroundImage});
        background-size: cover;
        background-position: center;
    ` : ''}
    width: 100%;
    height: 226px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.large}) {
        height: 240px;
    }
`;

export const CenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;