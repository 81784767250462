export enum BER_RATING {
  A1 = 'A1',
  A1A2 = 'A1A2',
  A1A3 = 'A1A3',
  A1B1 = 'A1B1',
  A1B2 = 'A1B2',
  A1B3 = 'A1B3',
  A1C1 = 'A1C1',
  A1C2 = 'A1C2',
  A1C3 = 'A1C3',
  A1D1 = 'A1D1',
  A1D2 = 'A1D2',
  A1E1 = 'A1E1',
  A1E2 = 'A1E2',
  A1F = 'A1F',
  A1G = 'A1G',
  A2 = 'A2',
  A2A3 = 'A2A3',
  A2B1 = 'A2B1',
  A2B2 = 'A2B2',
  A2B3 = 'A2B3',
  A2C1 = 'A2C1',
  A2C2 = 'A2C2',
  A2C3 = 'A2C3',
  A2D1 = 'A2D1',
  A2D2 = 'A2D2',
  A2E1 = 'A2E1',
  A2E2 = 'A2E2',
  A2F = 'A2F',
  A2G = 'A2G',
  A3 = 'A3',
  A3B1 = 'A3B1',
  A3B2 = 'A3B2',
  A3B3 = 'A3B3',
  A3C1 = 'A3C1',
  A3C2 = 'A3C2',
  A3C3 = 'A3C3',
  A3D1 = 'A3D1',
  A3D2 = 'A3D2',
  A3E1 = 'A3E1',
  A3E2 = 'A3E2',
  A3F = 'A3F',
  A3G = 'A3G',
  B1 = 'B1',
  B1B2 = 'B1B2',
  B1B3 = 'B1B3',
  B1C1 = 'B1C1',
  B1C2 = 'B1C2',
  B1C3 = 'B1C3',
  B1D1 = 'B1D1',
  B1D2 = 'B1D2',
  B1E1 = 'B1E1',
  B1E2 = 'B1E2',
  B1F = 'B1F',
  B1G = 'B1G',
  B2 = 'B2',
  B2B3 = 'B2B3',
  B2C1 = 'B2C1',
  B2C2 = 'B2C2',
  B2C3 = 'B2C3',
  B2D1 = 'B2D1',
  B2D2 = 'B2D2',
  B2E1 = 'B2E1',
  B2E2 = 'B2E2',
  B2F = 'B2F',
  B2G = 'B2G',
  B3 = 'B3',
  B3C1 = 'B3C1',
  B3C2 = 'B3C2',
  B3C3 = 'B3C3',
  B3D1 = 'B3D1',
  B3D2 = 'B3D2',
  B3E1 = 'B3E1',
  B3E2 = 'B3E2',
  B3F = 'B3F',
  B3G = 'B3G',
  BER_PENDING = 'BER_PENDING',
  C1 = 'C1',
  C1C2 = 'C1C2',
  C1C3 = 'C1C3',
  C1D1 = 'C1D1',
  C1D2 = 'C1D2',
  C1E1 = 'C1E1',
  C1E2 = 'C1E2',
  C1F = 'C1F',
  C1G = 'C1G',
  C2 = 'C2',
  C2C3 = 'C2C3',
  C2D1 = 'C2D1',
  C2D2 = 'C2D2',
  C2E1 = 'C2E1',
  C2E2 = 'C2E2',
  C2F = 'C2F',
  C2G = 'C2G',
  C3 = 'C3',
  C3D1 = 'C3D1',
  C3D2 = 'C3D2',
  C3E1 = 'C3E1',
  C3E2 = 'C3E2',
  C3F = 'C3F',
  C3G = 'C3G',
  D1 = 'D1',
  D1D2 = 'D1D2',
  D1E1 = 'D1E1',
  D1E2 = 'D1E2',
  D1F = 'D1F',
  D1G = 'D1G',
  D2 = 'D2',
  D2E1 = 'D2E1',
  D2E2 = 'D2E2',
  D2F = 'D2F',
  D2G = 'D2G',
  E1 = 'E1',
  E1E2 = 'E1E2',
  E1F = 'E1F',
  E1G = 'E1G',
  E2 = 'E2',
  E2F = 'E2F',
  E2G = 'E2G',
  F = 'F',
  FG = 'FG',
  G = 'G',
  NA = 'NA',
  SINo666of2006exempt = 'SINo666of2006exempt',
  SI_666 = 'SI_666',
}
