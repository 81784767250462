import styled, { DefaultTheme, css } from 'styled-components';
import * as Types from './Notification.types';

const getStatus = (theme: DefaultTheme, status: string | undefined) => {
  switch (status) {
    case "ERROR":
      return css`background: ${theme.toolkit.brand.error.LIGHTEST}; color: ${theme.toolkit.brand.error.DARK};`
    case "SUCCESS":
      return css`background: ${theme.toolkit.brand.success.LIGHTEST}; color: ${theme.toolkit.brand.success.BASE};`
    case 'INFORMATIONAL':
      return css`background: ${theme.toolkit.brand.primary.LIGHTEST}; color: ${theme.toolkit.brand.primary.BASE};`
    case 'GENERAL':
      return css`background: ${theme.toolkit.brand.grey?.LIGHTEST}; color: ${theme.toolkit.brand.grey?.DARK};`
    default:
      return '';
  }
};

const getIconStatus = (theme: DefaultTheme, status: string | undefined) => {
  switch (status) {
    case "ERROR":
      return css` color: ${theme.toolkit.brand.error.DARK};`
    case "SUCCESS":
      return css` color: ${theme.toolkit.brand.success.BASE};`
    case 'INFORMATIONAL':
      return css` color: ${theme.toolkit.brand.primary.BASE};`
    case 'GENERAL':
      return css` color: ${theme.toolkit.brand.grey?.DARK};`
    default:
      return '';
  }
};

const getSize = (theme: DefaultTheme, size: string | undefined) => {
  switch (size) {
    case "SMALL":
      return css`${theme.toolkit.typography.fontSizes.S12};`;
    case "MEDIUM":
      return css`${theme.toolkit.typography.fontSizes.S14};`
    case 'LARGE':
      return css`${theme.toolkit.typography.fontSizes.M16};`
    default:
      return '';
  }
};

const getSpacing = (theme: DefaultTheme, size: string | undefined) => {
  switch (size) {
    case "SMALL":
      return css`${theme.toolkit.spacing.S8};`;
    case "MEDIUM":
      return css`${theme.toolkit.spacing.S12};`
    case 'LARGE':
      return css`${theme.toolkit.spacing.M16};`
    default:
      return '';
  }
};

export const Wrapper = styled.div<Types.NotificationStyleProps>`
// change the bg colour based on the status type if the variant is 'InlineBG' set the background to transparent
${({ notificationStatus, notificationVariants, theme }) => notificationVariants === "InlineBG" ? css`background:transparent;` : getStatus(theme, notificationStatus)}
// add a box-shadow if it's the toast variant
${({ theme, notificationVariants }) => notificationVariants === 'Toast' && css`box-shadow: 0 0 10px ${theme.toolkit.brand.grey?.LIGHT};`}
// if the variant is inlineBG padding is 0, if it's large padding is 1rem else it's 0.75rem
padding: ${({ theme, notificationSizes, notificationVariants }) =>
  notificationVariants === 'InlineBG'
    ? theme.toolkit.spacing.S0
    : notificationSizes === 'LARGE'
    ? theme.toolkit.spacing.M16
    : theme.toolkit.spacing.S12};
border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
flex-wrap: wrap;
display:flex;
width:100%;
max-width: 100%;
justify-content:space-between;
overflow: hidden; 
position: relative;
justify-content: stretch;
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: top;
`;

export const StyledIcon = styled.span<Types.NotificationStyleProps>`
  display: flex;
  margin-right: ${({ theme, notificationSizes }) => getSpacing(theme, notificationSizes)};
  ${({ notificationStatus, notificationVariants, theme }) => notificationVariants === "InlineBG" ? getIconStatus(theme, notificationStatus) : ''}
  padding-top: ${({ theme }) => theme.toolkit.spacing.S2};

`;

export const ClosedIcon = styled.button<Types.NotificationStyleProps>`
  color: ${({ theme }) => theme?.toolkit?.brand?.grey?.DARK};
  position: absolute;
  right: ${({ theme, notificationSizes }) => getSpacing(theme, notificationSizes)};
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const Paragrapgh = styled.p<Types.NotificationStyleProps>`
  color: ${({ theme }) => theme?.toolkit?.brand?.grey?.DARKER};
  ${({ notificationSizes, theme }) => getSize(theme, notificationSizes)}
`;

export const Title = styled.p<Types.NotificationStyleProps>`
  padding-bottom: ${({ theme }) => theme.toolkit.spacing.S4};
  color: ${({ titleColor, theme }) => titleColor || theme?.toolkit?.brand?.grey?.DARKER};
  font-weight: ${({ theme }) => theme.toolkit.typography.fontWeights.SEMIBOLD};
  ${({ notificationSizes, theme }) => getSize(theme, notificationSizes)}
`;

export const TextWrapper = styled.div<Types.NotificationStyleProps>`
  display: flex;
  flex-direction: column;
`;

export const CtaWrapper = styled.div<Types.NotificationStyleProps>`
  margin-top: ${({ theme, notificationSizes }) =>
    notificationSizes === 'SMALL' ? theme.toolkit.spacing.S12 : theme.toolkit.spacing.M16};
  position: relative;
  display: flex;
  align-items: center;
  gap: ${({ theme, notificationSizes }) => getSpacing(theme, notificationSizes)};
`;
export const TextIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const TextIconCTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
