import * as Types from './Button.types';
import * as Styled from './Button.styled';
import { Spinner } from '../spinner/Spinner';

export const Button = ({
  buttonSize = Types.ButtonSizes.MEDIUM,
  buttonType = Types.ButtonTypes.PRIMARY,
  children,
  type,
  element = 'button',
  fullWidth = false,
  href,
  disabled,
  active,
  testId,
  classNames,
  isButtonGroup = false,
  isLinkButtonGroup = false,
  onClick,
  onFocus,
  onBlur,
  Icon,
  loader = false,
  position,
  ref,
  useLegacyMinWidth = true,
  noPadding,
  openInNewTab,
  ...rest
}: Types.ButtonProps) => {
  let IconType = '';
  if (Icon) IconType = Icon.props.icon;

  return (
    <Styled.Button
      hasChildren={children}
      Icon={IconType}
      isIconOnly={Boolean(!children && Icon)}
      buttonSize={buttonSize}
      buttonType={buttonType}
      loader={loader}
      fullWidth={fullWidth}
      position={'left'}
      {...(element === 'a' && href && { href: href })}
      {...(element === 'a' && href && openInNewTab && { target: '_blank' })}
      {...(element === 'button' && { type: type ? type : 'button' })}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      active={active}
      isButtonGroup={isButtonGroup}
      isLinkButtonGroup={isLinkButtonGroup}
      as={element && element}
      data-testid={`button-${testId || buttonType}`}
      {...(classNames && { className: `${classNames}` })}
      ref={ref}
      useLegacyMinWidth={useLegacyMinWidth}
      noPadding={noPadding}
      {...rest}
    >
      {loader && buttonType !== 'GHOST' ? (
        <Spinner data-testid="spinner" />
      ) : (
        <Styled.Container
          data-testid="iconLeft"
          position={position}
          hasChildren={children && Icon}
        >
          <Styled.IconStyling>{Icon}</Styled.IconStyling>
          <span>{children}</span>
        </Styled.Container>
      )}
    </Styled.Button>
  );
};
