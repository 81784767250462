import styled from 'styled-components';

export const MapWrapper = styled.div<{ desktopHeight?: number }>`
  position: relative;
  > div {
    width: 100% !important;
    height: 280px !important;
    max-height: 80vh !important;
    @media only screen and (min-width: 652px) {
      height: ${({ desktopHeight }) => (desktopHeight ? `${desktopHeight}px` : '360px')} !important;
    }
  }
`;

export const ControlWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.toolkit.spacing.S8};
  top: ${({ theme }) => theme.toolkit.spacing.M16};
  right: ${({ theme }) => theme.toolkit.spacing.M16};
`;

export const MapIcon = styled.div<{ draggable: boolean }>`
  width: ${({ theme }) => theme.toolkit.spacing.M32};
  height: ${({ theme }) => theme.toolkit.spacing.M32};
  ${({ theme, draggable }) =>
    draggable &&
    `
  .map-pin:hover {
    fill: ${theme.toolkit.brand.primary.DARKER};
  }
  .map-pin:active {
    fill: ${theme.toolkit.brand.primary.LIGHT};
  }
  `}
`;

 export const MapInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const MobileMapInfo = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.toolkit.spacing.S8};
  width: 100%;

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.large}) {
    width: 50%;
    margin-top: ${({ theme }) => theme.toolkit.spacing.M16};
  }
`;

export const MapButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.toolkit.spacing.M24};
  margin-top: ${({ theme }) => theme.toolkit.spacing.M16};
  width: 100%;

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.large}) {
    width: 50%;
    margin-top: 0;
  }
`;