import React from 'react';
import { useTheme } from 'styled-components';
import { TGridProps, TGridItemProps, TGridItemBreakpoint } from './Grid.type';
import { TSpacingValue } from '@dist-property-frontend/configs';
import * as Styled from './Grid.styled';

const getGridItemBreakpoints = (
  xs?: TGridItemBreakpoint,
  sm?: TGridItemBreakpoint,
  md?: TGridItemBreakpoint,
  lg?: TGridItemBreakpoint,
  xl: TGridItemBreakpoint = 'auto',
) => ({
  xs: xs || sm || md || lg || xl,
  sm: sm || md || lg || xl,
  md: md || lg || xl,
  lg: lg || xl,
  xl: xl,
});

const getGridSpacingsBreakpoints = (
  xs?: TSpacingValue,
  sm?: TSpacingValue,
  md?: TSpacingValue,
  lg?: TSpacingValue,
  xl: TSpacingValue = 'S0',
) => ({
  xs: xs || sm || md || lg || xl,
  sm: sm || md || lg || xl,
  md: md || lg || xl,
  lg: lg || xl,
  xl: xl,
});

export const Grid = ({
  columns = 12,
  alignItems,
  justifyContent,
  children,
  gridGap,
  columnGap,
  hideElement,
}: TGridProps) => {
  const theme = useTheme();
  const childrenWithProps = React.Children.map(children, (child) =>
    React.isValidElement(child)
      ? React.cloneElement<any>(child, { gridColumns: columns })
      : child,
  );

  return (
    <Styled.Grid
      columns={columns}
      gridGap={{
        ...getGridSpacingsBreakpoints(
          gridGap?.xs,
          gridGap?.sm,
          gridGap?.md,
          gridGap?.lg,
          gridGap?.xl,
        ),
      }}
      columnGap={{
        ...getGridSpacingsBreakpoints(
          columnGap?.xs,
          columnGap?.sm,
          columnGap?.md,
          columnGap?.lg,
          columnGap?.xl,
        ),
      }}
      alignItems={alignItems}
      justifyContent={justifyContent}
      hideElement={hideElement}
      theme={theme}
    >
      {childrenWithProps}
    </Styled.Grid>
  );
};

export const GridItem = (props: TGridItemProps) => {
  const {
    xs,
    md,
    sm,
    lg,
    xl,
    children,
    startColumn,
    spanColumns,
    rowStart,
    rowSpan,
    verticalSelfAlignment,
    itemTextAlign,
    hideElement,
    maxWrappedLines,
    extraPaddingTop,
  } = props;
  const theme = useTheme();

  return (
    <Styled.GridItem
      {...getGridItemBreakpoints(xs, sm, md, lg, xl)}
      startColumn={startColumn}
      spanColumns={spanColumns}
      rowStart={rowStart}
      rowSpan={rowSpan}
      hideElement={hideElement}
      itemTextAlign={itemTextAlign}
      verticalSelfAlignment={verticalSelfAlignment}
      maxWrappedLines={maxWrappedLines}
      extraPaddingTop={extraPaddingTop}
      theme={theme}
    >
      {children}
    </Styled.GridItem>
  );
};

export default { Grid, GridItem };
