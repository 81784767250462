import styled from "styled-components";

export const Toolbar = styled.div`
  display: flex;
  width: 100%;
`;

export const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.large}) {
    padding: 0;
    max-width: 1040px;
  }

`;

export const InfoPanel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flext-start;
  flex-direction: column;
  padding: 20px 0;
  margin-bottom: 1px;
  width: 100%;

  &:first-child {
    margin-top: 20px;
  }
`;

export const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
`;

export const SidePanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.color.GREY_LIGHTER};
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: ${({ theme }) => theme.toolkit.spacing.S12};
  max-width: 1040px;
  margin: 0 auto;
  gap: 8px;

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.large}) {
    display: grid;
    grid-template-columns: calc(70% - 72px) calc(30% - 12px);
    grid-template-rows: 1fr;
    gap: 84px;
    padding: 0 0 40px;
    position: relative;
  }
`;