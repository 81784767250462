import styled, { css, DefaultTheme } from 'styled-components';

/**
 * Checkbox styling in Figma: https://www.figma.com/design/cM5jrOpztBxcdQJN6YgXId/DAFT-PROPERTY-TOOLKIT-(NEW)?node-id=18779-32288&node-type=frame&t=A1s19fCS0dFpupQk-0
 */

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto ${({ theme }) => theme.toolkit.spacing.S12};
`;

const getBaseBorderColor = ({
  defaultBorderColorValue,
  disabled,
  hasError,
  isWrapperHovered,
  theme,
}: {
  defaultBorderColorValue?: string;
  disabled?: boolean;
  hasError: boolean;
  isWrapperHovered?: boolean;
  theme: DefaultTheme;
}) => {
  if (hasError && !disabled) {
    return isWrapperHovered
      ? theme.toolkit.brand.error?.DARKER
      : theme.toolkit.brand.error?.DARK;
  }
  if (!hasError && disabled) {
    return theme.toolkit.brand.grey?.LIGHTER;
  }
  if (hasError && disabled) {
    return theme.toolkit.brand.error.LIGHTER;
  }

  return isWrapperHovered
    ? theme.toolkit.brand.primary?.DARK
    : defaultBorderColorValue;
};

// This function deals with styling the checkbox span element when it is in the unchecked or non intermediate state
const getBaseStyles = ({
  defaultHasBackground,
  defaultBorderColorValue,
  disabled,
  hasError,
  isWrapperHovered,
  theme,
}: {
  defaultHasBackground?: boolean;
  defaultBorderColorValue?: string;
  disabled?: boolean;
  hasError: boolean;
  isWrapperHovered?: boolean;
  theme: DefaultTheme;
}) => {
  let background = theme.toolkit.brand.primary.TEXTCONTRAST;
  if (!defaultHasBackground) background = 'transparent';
  if (disabled) background = theme.toolkit.brand.grey?.LIGHTER || 'transparent';

  const borderColor = getBaseBorderColor({
    defaultBorderColorValue,
    disabled,
    hasError,
    isWrapperHovered,
    theme,
  });

  return css`
    background: ${background};
    border: 1px solid ${borderColor};
  `;
};

const getCheckedBackgroundColor = ({
  disabled,
  hasError,
  isWrapperHovered,
  theme,
}: {
  disabled?: boolean;
  hasError: boolean;
  isWrapperHovered?: boolean;
  theme: DefaultTheme;
}) => {
  if (hasError && !disabled) {
    return isWrapperHovered
      ? theme.toolkit.brand.error.DARKER
      : theme.toolkit.brand.error.DARK;
  }
  if (!hasError && disabled) {
    return theme.toolkit.brand.primary.LIGHTER;
  }
  if (hasError && disabled) {
    return theme.toolkit.brand.error.LIGHTER;
  }

  return isWrapperHovered
    ? theme.toolkit.brand.primary.DARKER
    : theme.toolkit.brand.primary.BASE;
};

// This function deals with styling the checkbox span element when it is in checked or indeterminate state
const getCheckedStyles = ({
  checked,
  disabled,
  indeterminate,
  hasError,
  isWrapperHovered,
  theme,
}: {
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  hasError: boolean;
  isWrapperHovered?: boolean;
  theme: DefaultTheme;
}) => {
  if (!(checked || indeterminate)) return null;

  const checkedBackgroundColor = getCheckedBackgroundColor({
    disabled,
    hasError,
    isWrapperHovered,
    theme,
  });

  const sharedStyles = css`
    background: ${checkedBackgroundColor};
    border: none;
    box-shadow: none;
  `;

  if (indeterminate) {
    return css`
      ${sharedStyles};

      &:after {
        background: ${theme.toolkit.brand.primary.TEXTCONTRAST};
        height: ${theme.toolkit.spacing.S2};
        left: 50%;
        opacity: 1;
        top: 50%;
        transform: translate(-50%, -50%);
        width: ${theme.toolkit.spacing.M16};
      }
    `;
  }

  return css`
    ${sharedStyles};

    &:after {
      opacity: 1;
    }
  `;
};

const getCheckboxStyles = ({
  checked,
  defaultHasBackground,
  defaultBorderColorValue,
  disabled,
  indeterminate,
  hasError,
  isWrapperHovered,
  theme,
}: {
  checked?: boolean;
  defaultHasBackground?: boolean;
  defaultBorderColorValue?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  hasError: boolean;
  isWrapperHovered?: boolean;
  theme: DefaultTheme;
}) => {
  if (checked || indeterminate) {
    return getCheckedStyles({
      checked,
      disabled,
      indeterminate,
      hasError,
      isWrapperHovered,
      theme,
    });
  }

  return getBaseStyles({
    defaultHasBackground,
    defaultBorderColorValue,
    disabled,
    hasError,
    isWrapperHovered,
    theme,
  });
};

export const Checkbox = styled.span<{
  checked?: boolean;
  defaultBorderColorValue?: string;
  defaultHasBackground?: boolean;
  disabled?: boolean;
  hasError: boolean;
  indeterminate: boolean;
  isWrapperHovered?: boolean;
}>`
  align-self: center;
  border-radius: ${({ theme }) => theme.toolkit.spacing.S2};
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: ${({ theme }) => theme.toolkit.spacing.M20};
  position: relative;
  transition: all 0.2s;
  width: ${({ theme }) => theme.toolkit.spacing.M20};

  &:after {
    border-color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
    border-style: solid;
    border-width: 0 ${({ theme }) => theme.toolkit.spacing.S2}
      ${({ theme }) => theme.toolkit.spacing.S2} 0;
    content: '';
    height: 14px;
    left: 6px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 1px;
    transform: rotate(45deg);
    transition: all 0.2s ease-out;
    width: ${({ theme }) => theme.toolkit.spacing.S8};
  }

  ${({
    checked,
    defaultHasBackground,
    defaultBorderColorValue,
    disabled,
    indeterminate,
    hasError,
    isWrapperHovered,
    theme,
  }) =>
    getCheckboxStyles({
      checked,
      defaultHasBackground,
      defaultBorderColorValue,
      disabled,
      hasError,
      indeterminate,
      isWrapperHovered,
      theme,
    })};
`;

export const CheckboxWrapper = styled.div<{
  variant: string;
}>`
  display: flex;
  position: relative;
  input {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  // Focus states
  input:focus:not(:focus-visible) ~ ${Checkbox} {
    outline: none;
  }

  input:focus:focus-visible ~ ${Checkbox} {
    border-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
    box-shadow: ${({ theme }) =>
      `0 0 0 ${theme.toolkit.spacing.S4} ${theme.toolkit.brand.grey?.LIGHT}`};
    outline: none;
  }
`;

export const IconWrapper = styled.div`
  width: ${({ theme }) => theme.toolkit.spacing.M24};
  height: ${({ theme }) => theme.toolkit.spacing.M24};
  margin: auto 0 auto auto;
`;

export const LabelWrapper = styled.div`
  margin: auto 0;
`;

export const SubtextWrapper = styled.div<{ subtext?: string }>`
  ${({ subtext, theme }) =>
    subtext && `padding-top: ${theme.toolkit.spacing.S4};`}
`;
