import styled from 'styled-components';
import { IconStyleProps } from './Icons.types';

export const IconWrapper = styled.span<IconStyleProps>`
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
  svg{
    width: inherit;
    height: inherit;
    color: ${({ color }) => color};
  }
`;
