import * as Types from './MetaLabel.types';
import * as Styled from './MetaLabel.styled';

export const MetaLabel = ({
  MetaLabelSizes,
  metaProps,
  textColor = Types.MetaLabelColors.GREY_DARK,
  showContent = true,
  toggleText = false,
}: Types.MetaLabelProps) => {
  const params = metaProps;
  return (
    <Styled.Wrapper toggleText={toggleText} MetaLabelSizes={MetaLabelSizes}>
      <Styled.InnerWrapper
        textColor={textColor}
        toggleText={toggleText}
        showContent={showContent}
        MetaLabelSizes={MetaLabelSizes}
      >
        {params.map((param, index) => (
          <span key={index}>{param}</span>
        ))}
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};
