import { IllustrationName } from '../../atoms/illustrations/Illustration.types';

type HeightValue = 32 | 40 | 64 | 96 | 112;

export enum HeaderSizes {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum IconPosition {
  TOP = 'TOP',
  LEFTCENTER = 'LEFTCENTER',
  LEFTTOP = 'LEFTTOP',
}

export type HeaderStyleProps = {
  headerSizes?: HeaderSizes;
  iconPosition?: IconPosition;
};

export type HeaderProps = {
  children?: JSX.Element | string;
  headerSizes?: HeaderSizes;
  iconPosition?: IconPosition;
  subtitle?: boolean;
  setIcon?: boolean;
  text?: string;
  subtitleText?: string;
  illustrationName?: IllustrationName;
  height?: HeightValue;
};
