import { CardContent } from '../../../organisms';
import {
  Badge,
  BadgeColor,
  BadgeStyle,
  BerIcon,
  MetaLabel,
  MetaLabelSizes,
  Text,
  TextFontWeight,
  TextSize,
} from '../../../atoms';
import { BER_RATING } from '@dist-property-frontend/types';
import { ReactNode } from 'react';

type CustomCardContentType = {
  props: CardContentUIType;
};

export type CardContentUIType = {
  subtitle: string;
  metaProps: any;
  ber: BER_RATING;
  viewings: string;
  sticker: string;
  stickerTestId: string;
  titlePrice: JSX.Element | string | ReactNode;
  titleTagline: string;
  topRightSlot: JSX.Element;
  advantageLabel?: boolean;
  agentBranch?: string;
  hideBottomSlot?: boolean;
  dataTracking?: { [key: string]: string };
};

export const CustomCardContent = (
  contentProps: CustomCardContentType,
): JSX.Element => {
  const {
    subtitle,
    metaProps,
    ber,
    viewings,
    sticker,
    stickerTestId,
    titlePrice,
    titleTagline,
    topRightSlot,
    dataTracking,
  } = contentProps.props;

  return (
    <>
      <CardContent.Row
        startColumn={1}
        spanColumns={11}
        maxWrappedLines={2}
        dataTracking={dataTracking?.['subtitle']}
      >
        <Text as="p" size={TextSize.S14} fontWeight={TextFontWeight.SEMIBOLD}>
          {subtitle}
        </Text>
      </CardContent.Row>
      {titleTagline && (
        <CardContent.Row
          startColumn={1}
          spanColumns={11}
          dataTracking={dataTracking?.['tagline']}
          maxWrappedLines={2}
        >
          <Text as="p" size={TextSize.S14} textColor="#333333">
            {titleTagline}
          </Text>
        </CardContent.Row>
      )}
      {!titleTagline && (
        <CardContent.Row
          startColumn={1}
          spanColumns={11}
          dataTracking={dataTracking?.['price']}
        >
          {titlePrice}
        </CardContent.Row>
      )}
      {topRightSlot && (
        <CardContent.CardContentTopRightSlot>
          {topRightSlot}
        </CardContent.CardContentTopRightSlot>
      )}
      {metaProps && metaProps.length > 0 && (
        <CardContent.Row
          startColumn={1}
          spanColumns={12}
          dataTracking={dataTracking?.['metaLabel']}
        >
          <MetaLabel
            metaProps={metaProps}
            MetaLabelSizes={MetaLabelSizes.MEDIUM}
          />
        </CardContent.Row>
      )}
      {(ber || viewings) && (
        <CardContent.Row startColumn={1} spanColumns={8}>
          <div
            style={{
              display: 'flex',
              gap: '8px',
            }}
          >
            {ber && (
              <BerIcon
                rating={BER_RATING[ber]}
                {...(dataTracking && {
                  'data-tracking': dataTracking?.['ber'],
                })}
              />
            )}
            {viewings && (
              <Text
                as="span"
                size={TextSize.S12}
                {...(dataTracking && {
                  'data-tracking': dataTracking?.['viewing'],
                })}
              >
                {viewings}
              </Text>
            )}
          </div>
        </CardContent.Row>
      )}

      {sticker && (
        <CardContent.Row
          startColumn={1}
          spanColumns={8}
          extraPaddingTop={8}
          dataTracking={dataTracking?.['sticker']}
        >
          <Badge
            data-testid={stickerTestId}
            badgeStyle={BadgeStyle.FILLED_LIGHT}
            badgeColor={BadgeColor.GREY_DARK}
          >
            {sticker}
          </Badge>
        </CardContent.Row>
      )}
    </>
  );
};
