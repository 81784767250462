import Map, { Marker } from 'react-map-gl/maplibre';
import { Button, ButtonSizes, ButtonTypes, Icon, Text, TextFontWeight } from '../../atoms';
import { MapIcons } from './MapIcons';
import { useMap } from './Map.hook';
import { TInterativeMap } from './Map.types';
import { MapWrapper, MapInfo, MapIcon, ControlWrapper, MapButtons, MobileMapInfo } from './Map.styled';


const InteractiveMap = (props: TInterativeMap) => {
  const { id, variant, draggable = true, desktopHeight, showMapButtons, showRecenterButton, isMobile } = props;

  const {
    mapRef,
    viewport,
    selected,
    onMapClick,
    handleZoomIn,
    handleZoomOut,
    handleRecenter,
    onMarkerDragEnd,
  } = useMap(props);

  // Fallback values for latitude and longitude if they are 0
  const longitude = props.marker.longitude === 0 ? -7.6283888: props.marker.longitude;
  const latitude = props.marker.latitude === 0 ? 53.5045879 : props.marker.latitude;
  const defaultViewPort =  props.marker.latitude !== 0 && props.marker.longitude !== 0 ? viewport :{
    latitude: 53.5045879, // dafault latitude for Ireland if no PPR data returns the lat lng for a property
    longitude: -7.6283888, // dafault longitude for Ireland if no PPR data returns the lat lng for a property
    zoom: 6,
  };

  const showMarker = props.marker.latitude !== 0 && props.marker.longitude !== 0;

  const renderMapButtons = () => {
    return (
        <MapButtons>
          <Button
            buttonType={ButtonTypes.TERTIARY}
            buttonSize={ButtonSizes.SMALL}
            fullWidth
            element="a"
            Icon={!isMobile ? <Icon icon={'NEWTAB'} size={16} /> : undefined}
            href={`https://www.google.com/maps?t=k&q=loc:${props.marker.latitude}+${props.marker.longitude}`}
            openInNewTab={true}
          >Satellite View</Button>
          <Button
            buttonType={ButtonTypes.TERTIARY}
            buttonSize={ButtonSizes.SMALL}
            fullWidth
            element="a"
            Icon={!isMobile ? <Icon icon={'NEWTAB'} size={16} /> : undefined}
            href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${props.marker.latitude},${props.marker.longitude}`}
            openInNewTab={true}
          >Street View</Button>
        </MapButtons>
    );
  }

  return (
  <>
    {showMapButtons && (
      <MapInfo>
        <Text variant={"h3"} fontWeight={TextFontWeight.SEMIBOLD}>Map</Text>
        {!isMobile && renderMapButtons()}
      </MapInfo>
    )}
    <MapWrapper data-tracking="map-interaction" desktopHeight={desktopHeight}>
      <Map
        id={id}
        initialViewState={defaultViewPort}
        ref={mapRef}
        mapStyle="https://api.maptiler.com/maps/51896624-22c9-466c-a329-a94c71f30a0d/style.json?key=94POrAmV6YVyhXBXGw8R"
        onClick={onMapClick}
      >
        {selected && showMarker && (
          <Marker
            longitude={longitude}
            latitude={latitude}
            anchor="bottom"
            offset={[0, 55.33]}
          >
            {MapIcons['MAPPIN_BIG']}
          </Marker>
        )}

        {showMarker && (
          <Marker
            longitude={longitude}
            latitude={latitude}
            anchor="bottom"
            draggable={draggable}
            onDragEnd={onMarkerDragEnd}
            offset={[0, 1.33]}
          >
              <MapIcon draggable={draggable}>
                {MapIcons['MAPPIN']}
              </MapIcon>
          </Marker>
        )}
        <ControlWrapper>
          <Button
            buttonType={ButtonTypes.TERTIARY}
            buttonSize={
              variant === 'medium'
                ? ButtonSizes.SMALL
                : ButtonSizes.X_SMALL
            }
            Icon={<Icon icon={'PLUS'} size={16} />}
            onClick={handleZoomIn}
          />
          <Button
            buttonType={ButtonTypes.TERTIARY}
            buttonSize={
              variant === 'medium'
                ? ButtonSizes.SMALL
                : ButtonSizes.X_SMALL
            }
            Icon={<Icon icon={'MINUS'} size={16} />}
            onClick={handleZoomOut}
          />
          {showRecenterButton && 
            <Button
              buttonType={ButtonTypes.TERTIARY}
              buttonSize={
                variant === 'medium'
                  ? ButtonSizes.SMALL
                  : ButtonSizes.X_SMALL
              }
              Icon={<Icon icon={'MAPPIN'} size={16} />}
              onClick={handleRecenter}
            />
          }
        </ControlWrapper>
      </Map>
    </MapWrapper>
    {isMobile && showMapButtons && (
      <MobileMapInfo>
        {renderMapButtons()}
      </MobileMapInfo>
    )}
    </>
  );
};

export { InteractiveMap };
