import styled from 'styled-components';
import { THeaderStyled } from './Header.types';

export const HeaderWrapper = styled.header<THeaderStyled>`
  background: ${({ theme, bgColor }) =>
    theme.toolkit.brand.background[
      bgColor as keyof typeof theme.toolkit.brand.background
    ]};

  height: ${({ theme }) => theme.toolkit.spacing.XL64};
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.toolkit.spacing.M16};
  @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
    height: ${({ theme }) => theme.toolkit.spacing.XL72};
    padding: 0 ${({ theme }) => theme.toolkit.spacing.M32};
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    margin: 0 auto;
    align-items: center;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
`;

export const BurgerWrapper = styled.div`
  z-index: 3;
  @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
    display: none;
  }
`;

export const MainNav = styled.nav<THeaderStyled>`
  list-style-type: none;
  @media ${({ theme }) => theme.toolkit.mediaQuery.smallOnly} {
    background: ${({ theme }) => theme.toolkit.brand.background.primary};
    padding: ${({ theme }) => theme.toolkit.spacing.XL64}
      ${({ theme }) => theme.toolkit.spacing.M24}
      ${({ theme }) => theme.toolkit.spacing.M24};
    transform: translate(100%);
    position: fixed;
    right: 0;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 18.75rem;
  }
  @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
    margin-left: auto;
  }
  @media ${({ theme }) => theme.toolkit.mediaQuery.smallOnly} {
    &.show {
      transform: translate(0);
    }
    &.hide {
      transform: translate(100%);
    }
  }
`;

export const UtilityNav = styled.ul`
  margin-left: auto;
`;
