import * as Styled from './InfoTitle.styled';
import { InfoTitleProps, InfoTitleSize } from './InfoTitle.types';

export const InfoTitle = ({
  heading,
  badge,
  subHeading,
  metaLabel,
  button,
  img,
  imgPlacement,
  size = InfoTitleSize.MEDIUM,
  color,
}: InfoTitleProps) => {
  return (
    <Styled.Wrapper imgPlacement={imgPlacement} size={size}>
      {img && <Styled.ImgWrapper>{img}</Styled.ImgWrapper>}
      <div>
        <Styled.Content size={size} color={color}>
          {heading}
          {subHeading}
          {metaLabel}
          {badge}
        </Styled.Content>
        {button && (
          <Styled.ButtonWrapper size={size}>{button}</Styled.ButtonWrapper>
        )}
      </div>
    </Styled.Wrapper>
  );
};
