import { forwardRef, useState } from 'react';
import * as Styled from './Checkbox.styled';
import * as Atom from '../../atoms';
import * as Types from './Checkbox.types';
import * as TextTypes from '../../atoms/text/Text.types';
import * as LabelTypes from '../../atoms/label/Label.types';
import { useTheme } from 'styled-components';

export const Checkbox = forwardRef<HTMLInputElement, Types.TCheckbox>(
  (props: Types.TCheckbox, ref) => {
    const {
      checked,
      disabled = false,
      defaultBorderColor = 'LIGHT',
      defaultHasBackground = true,
      hasError = false,
      icon,
      indeterminate = false,
      inputLabel,
      labelWeight = LabelTypes.LabelWeight.REGULAR,
      secondary,
      subtext,
      variant = 'medium',
      ...rest
    } = props;
    const theme = useTheme();
    const [isWrapperHovered, setIsWrapperHovered] = useState(false);
    const greyTheme = theme.toolkit.brand.grey;
    const defaultBorderColorValue =
      defaultBorderColor === 'LIGHT' ? greyTheme?.LIGHT : greyTheme?.DARK;

    return (
      <Styled.CheckboxWrapper
        onMouseEnter={() => setIsWrapperHovered(true)}
        onMouseLeave={() => setIsWrapperHovered(false)}
        variant={variant}
      >
        <input
          type={'checkbox'}
          ref={ref}
          checked={checked}
          disabled={disabled}
          {...rest}
        />
        <Styled.Checkbox
          checked={checked}
          defaultBorderColorValue={defaultBorderColorValue}
          defaultHasBackground={defaultHasBackground}
          disabled={disabled}
          hasError={hasError}
          indeterminate={indeterminate}
          isWrapperHovered={isWrapperHovered}
        />
        {inputLabel && (
          <Styled.TextWrapper>
            <Styled.LabelWrapper>
              <Atom.Label
                labelColor={disabled ? greyTheme?.BASE : greyTheme?.DARKER}
                secondaryColor={disabled ? greyTheme?.BASE : greyTheme?.DARK}
                secondaryText={secondary}
                size={Types.VariantMap[variant].textSize}
                weight={labelWeight}
              >
                {inputLabel}
              </Atom.Label>
            </Styled.LabelWrapper>
            {secondary && (
              <Styled.SubtextWrapper subtext={subtext}>
                <Atom.Text
                  fontWeight={TextTypes.TextFontWeight.REGULAR}
                  size={TextTypes.TextSize.S12}
                  textColor={disabled ? greyTheme?.BASE : greyTheme?.DARK}
                  variant={'span'}
                >
                  {subtext}
                </Atom.Text>
              </Styled.SubtextWrapper>
            )}
          </Styled.TextWrapper>
        )}

        {icon && <Styled.IconWrapper>{icon}</Styled.IconWrapper>}
      </Styled.CheckboxWrapper>
    );
  },
);

export default Checkbox;
