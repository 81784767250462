import { Banner, ContentWrapper, InfoPanel, MainWrapper, SidePanel, SideWrapper, Toolbar } from './PDPTemplate.styled';
import { TPDPTemplate } from './PDPTemplate.types';

const PDPTemplate = (props: TPDPTemplate) => {
  const { toolbarSlot, bannerSlot, infoPanels, sidePanels } = props;
  return (
    <>
      <Toolbar>
        {toolbarSlot}
      </Toolbar>

      <Banner>
        {bannerSlot}
      </Banner>

      <ContentWrapper>
        {/* This maps through the infoPanels and renders them in a MainWrapper */}
        <MainWrapper>
          {infoPanels?.map((infoPanel, index) => (
            <InfoPanel key={`infoPanel-${index + 1}`}>{infoPanel}</InfoPanel>
          ))}
        </MainWrapper>

        {/* This maps through the sidePanels and renders them in a SideWrapper */}
        <SideWrapper>
          {sidePanels?.map((sidePanel, index) => (
            <SidePanel key={`sidePanel-${index + 1}`}>{sidePanel}</SidePanel>
          ))}
        </SideWrapper>
      </ContentWrapper>
    </>
  )
}

export { PDPTemplate };