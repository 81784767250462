import * as Styled from './Container.styled';
import { ContainerProps } from './Container.types';

export const Container = ({
  backgroundColor,
  paddingX,
  maxWidth,
  children,
}: ContainerProps) => {
  return (
    <Styled.Container
      backgroundColor={backgroundColor}
      maxWidth={maxWidth}
      paddingX={paddingX}
    >
      {children}
    </Styled.Container>
  );
};

export default Container;
