export enum NotificationFontSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum NotificationStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INFORMATIONAL = 'INFORMATIONAL',
  GENERAL = 'GENERAL',
}

export enum NotificationVariants {
  Toast = 'Toast',
  Inline = 'Inline',
  InlineBG = 'InlineBG',
}

export type NotificationStyleProps = {
  notificationSizes?: NotificationFontSizes;
  notificationStatus?: NotificationStatus;
  notificationVariants?: NotificationVariants;
  titleColor?: string;
};

export type NotificationProps = {
  notificationSizes?: NotificationFontSizes;
  notificationStatus?: NotificationStatus;
  notificationVariants?: NotificationVariants;
  closeIcon?: boolean;
  text?: React.ReactNode;
  title?: string;
  titleColor?: string;
  icon?: React.ReactNode;
  cta?: React.ReactNode;
  onChange?: () => void;
};
