export enum MetaLabelSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum MetaLabelColors {
  GREY_DARK = 'GREY_DARK',
  GREY_DARKER = 'GREY_DARKER',
}

export type MetaLabelStyle = {
  MetaLabelSizes?: MetaLabelSizes;
  textColor?: MetaLabelColors;
  showContent?: boolean;
  toggleText?: boolean;
};

export interface MetaLabelProps extends MetaLabelStyle {
  metaProps: string[];
}
