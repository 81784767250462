
import { Text, TextFontWeight} from "../../atoms";
import { InfoTitle } from "../../molecules";
import { NoImageBannerWrapper } from "./NoImageBanner.styled";
import {TNoImageBannerProps } from "./NoImageBanner.types";


const NoImageBanner = ({
  backgroundColor,
  backgroundImage,
  titleText,
  titleTextSize,
  subtitleText,
  subtitleTextSize,
  color,
}: TNoImageBannerProps): JSX.Element => {


    return (
      <NoImageBannerWrapper backgroundColor={backgroundColor} backgroundImage={backgroundImage} data-testid={'no-image-banner-wrapper'}>
        <InfoTitle 
          heading={<Text as="h2" fontWeight={TextFontWeight.SEMIBOLD} size={titleTextSize}>{titleText}</Text>}
          subHeading={<Text as="h3" size={subtitleTextSize}>{subtitleText}</Text>}
          color={color}
        />
      </NoImageBannerWrapper>
    );
}

export { NoImageBanner };